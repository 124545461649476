var addIdentifier = function (idName, idText, idFind, idType) {
    if (typeof idFind === 'undefined'){
        idFind = 'a';
    }

    var count = 0;
    $(idName).find(idFind).each( function() {
        
        if(idType === "menu"){
            var idMenu = $(this).clone();
                idMenu.find('small').remove().end();
                idMenu.find('span').remove().end();
                idMenu.find('img').remove().end();
                idMenu = idMenu.text().trim().toLowerCase().replace(/\s/g, '_').replace(/\!/g, '');
                
            if ($(this).prop("id").length === 0 && $(this).text() !== ""){
                $(this).attr('id', idText + idMenu);
            }
        
        } else if(idType === "links") {
            var idAdd = $(this).clone();
                idAdd = idAdd.text().trim().toLowerCase().replace(/\s/g, '_').replace(/</g, 'under').replace(/\+/g, 'plus').replace(/ \+/g, 'plus').replace(/\-/g, 'to');
            
            if ($(this).prop("id").length === 0 && $(this).text() !== ""){
                $(this).attr('id', idText + idAdd);
            }
        
        }else if(idType === "recNumber") {
            if ($(this).prop("id").length === 0 && $(this).text() !== ""){
                $(this).attr('id', idText + count++);
            }
        
        } else{
            
            if ($(this).prop("id").length === 0){
                $(this).attr('id', idText);
            };
        }
    
    });
};

$(function() {

    // Add ID on header
    addIdentifier('header .logo, .flexi-col-left, header .header__left, #header, .logo, .navbar-header .navbar-brand, .mega-menu h1, #guide__page-v2 .navbar-brand', 'hdr_logo', 'img');
    addIdentifier('.dropdown-menu, .dropdown-menu-list', 'hdr_submenu_', 'a' ,'menu');
    addIdentifier('.navbar-nav, .flexi-col-right, nav.mega-menu, .mega-menu-wrap ul', 'hdr_menu_', 'a' ,'menu');

    // Add ID on phone contact
    addIdentifier('.phone-details, .contact-details', 'label_officehour', 'small, span');
    addIdentifier('.phone-details a, .contact-details a, .header__phone-right', 'label_officecontact', 'span, strong');

    // Add ID on Social link
    addIdentifier('.social-box, .widgets__v2 .social__icons-v2', 'social_popup', '.footer-v2 .social_popup, .social_popup', 'recNumber');
    addIdentifier('footer .custom-footer__social-list, .footer__socials, .list-unstyled.list-inline, .social__icons-v2', 'ftr_social', 'a', 'recNumber');
    

    // Add ID on Footer
    addIdentifier('footer .footer-menu, footer .footer-nav, .footer .footer-nav, #menu-footer-menu, .list-footer .list-unstyled, .footer__list, .extra-links', 'ftr_link_', 'a', 'menu');
    addIdentifier('.list-footer', 'ftr_logo', 'img.logo-footer');
    addIdentifier('.list-footer .navbar-brand, .footer__logo, .footer__logo-v2', 'ftr_logo', 'img');
    addIdentifier('footer, footer .disclaimer-text, .footer .disclaimer-text, footer .disclaimer, footer .footer-bottom', 'ftr_disclaimer', 'p, p.description', 'recNumber');

    // Add ID on widget popular
    addIdentifier('.popular-post, .popular-box__flexi-v2', 'popular_post', 'a', 'recNumber');

    // Add ID on common button
    addIdentifier('.edm_modal_fields', 'submit_btn', 'button');
    addIdentifier('.request-call__info-details', 'btn_details', 'a');
    addIdentifier('.insurance-companies-wrapper', 'btn_link_companies', 'a', 'recNumber');
    addIdentifier('.article-content-wrapper .main-blog-post .main-blog-post-title', 'link_blog_post', 'a', 'recNumber');
    addIdentifier('.article-content-wrapper .main-blog-post', 'btn_blog_post', 'a', 'recNumber');
    addIdentifier('.archive-post .archive-list', 'archive_link', 'a', 'recNumber');
    addIdentifier('.main-blog-pagination', 'pagination_link', 'a, span', 'recNumber');

    // Add ID on CTA
    addIdentifier('header .header__right', 'btn_cta_header', 'a');
    addIdentifier('.compare-button-wrapper, .providersBlock__action, .footer__cta-action', 'btn_cta_footer', 'a, .providersBlock__action-cta');
    addIdentifier('.articleWrapper__article, .main-content__cta-box', 'btn_cta', '.btn--bodyCTA, a.btn', 'recNumber');
    addIdentifier('.hic-flex-feat-img-btn', 'btn_cta', 'a');
    addIdentifier('.desktop__map-flex, .flexi-highlight-content', 'btn_cta_map', 'a.flexi-arrow-btn');

    // Add ID on Widget Lifestage
    addIdentifier('.articleWrapper__article, .ageSelectorWrappers', 'widget_lifestage', '.ageSelector, .choose__life-stage-wrapper');
    addIdentifier('#widget_lifestage', 'widget_lifestage_', 'a', 'links');
    addIdentifier('.content', 'display_content', '.hide-in-mobile, .hide-in-desktop', 'recNumber');
    addIdentifier('.content, .life_stage_section, .lifestage-widget', 'widget_lifestage', '.hide-in-mobile, .hide-in-desktop, .hic-flexi-sidebar-content, .age-options-container', 'recNumber');
    addIdentifier('#widget_lifestage0', 'widget_lifestage0_', 'a', 'links');
    addIdentifier('#widget_lifestage1', 'widget_lifestage1_', 'a', 'links');
    addIdentifier('#widget_lifestage2', 'widget_lifestage2_', 'a', 'links');
    addIdentifier('#widget_lifestage3', 'widget_lifestage3_', 'a', 'links');
    
    // Add ID on Widget Age
    addIdentifier('.sidebar', 'widget_age', '.age-options');
    addIdentifier('#widget_age', 'widget_age_', 'a', 'links');

     // Add ID on new flexi popular post widget
     addIdentifier('.sidebar__left', 'widget_popular', '.most__viewed-v2');
     addIdentifier('.most__viewed-v2 .flexi__popular-box', 'widget_popular_btn', 'a.flexi__popular-post-box-link', 'recNumber');

     // Add ID on map
    addIdentifier('.articleWrapper__article', 'widget_map', '.au-map');
    addIdentifier('#widget_map .mobile__map-flex', 'map-', 'a', 'links');
    addIdentifier('.content .inpost-button', 'btn_cta_map', 'a');
    addIdentifier('.hic-flexi-highlight-content', 'btn_cta_map', 'a.svg-holder, a.hic-flexi-arrow-btn', 'recNumber');
    addIdentifier('.content .state-on-mobile, .hic-flexi-highlight-content .state-on-mobile', 'map-', 'a', 'links');

    // Add ID on new flexi recommendation widget
    addIdentifier('.flexi__widget-section, .recommended__section', 'widget_recommendation', '.recommended__wrapper', 'recNumber');

});